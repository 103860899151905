body {
  font-family: Arial, sans-serif;
}
.chatroom-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow-y: auto;
}

.input-form {
  display: flex;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.messageInput {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 30px !important;
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 60px;
  height: 80px;
}

.send-icon-wrapper > svg {
  margin-left: auto;
  margin-right: auto;
}

.clear {
  margin-left: 10px;
  padding: 11px;
  border: none;
  border-radius: 5px;
  background-color: #02786e;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.message-list-wrapper {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
}
