body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}
.bot-message {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 10px;
}

.message {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
  text-align: left;
}

.profile-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-top: 8px;
}

.conversation-container {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  display: grid;
  grid-template-columns: 1fr 9fr;
  overflow-y: scroll; /* Enable scrolling */
  margin-bottom: 20px;
}

.conversation-parent-container {
  margin-bottom: 31px;
}
.message-content {
  font-size: 24px;
}
