body {
  font-family: Arial, sans-serif;
}

.content-container {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.uploadedContent {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
}

.pdfWindow {
  background-color: grey;
  height: 100vh;
  display: inline-block;
  overflow-y: auto;
  width: 33%;
}

.rightPanel {
  height: 100vh;
  display: inline-block;
  overflow-y: auto;
  width:67%;
  padding: 0px 20px 0px 20px;
}

.rightPanelContent {
  display:flex;
  flex-flow: row;
  flex-direction: column;
  height: 100%;
}

.sidebar {
  position:fixed;
  justify-content: space-between;
}

.sidebar-upper {
  display: flex;
  flex-direction: column;
}

.agent-selector {
  font-size: 25px;
  line-height: 30px;
  display: flex;
  color: #00e0b5;
}

@media (min-width: 768px) {
  .navbar {
    visibility: hidden;
    min-height: 70px;
  }

  .container.nav-container {
    visibility: hidden;
    height: 100%;
  }

  .checkbox {
    visibility: hidden;
  }
  .hamburger-lines {
    visibility: hidden;
  }
  .menu-items {
    visibility: hidden;
    height: 97%;
  }
  .sidebar {
    visibility: visible;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    margin: auto;
  }

  .navbar {
    width: 100%;
    box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
  }

  .navbar .menu-items {
    display: flex;
  }

  .navbar .nav-container li {
    list-style: none;
  }

  .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  }

  .navbar .nav-container a:hover {
    font-weight: bolder;
  }

  .nav-container {
    display: block;
    position: relative;
    height: 60px;
    width: 100%;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #fff;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .menu-items {
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  .navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}

.loading-section {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 45%;
}

@media (max-width: 767px) {
  .loading-section {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.sidebar-title {
  margin-bottom: 10px;
  padding-left: 20px;
  font-weight: 550;
  color: #fff;
  text-align: center;
}

.omaha {
  color: #1B3864;
}