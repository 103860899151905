.termBox {
    margin: 0px 10px 10px 10px;
    padding: 10px 0px 10px 0px;
    border-radius: 20px;
    border-color: #000;
    border-width: 2px;
    text-align: left;
    display: inline-block;
    width: 300px;
    position: relative;
}

.termDetails {
    display:inline-block;
    width:260px;
    padding-left:20px
}

.termDot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
  }

.termStatusGreen {
    border-color: #a0c15a;
    box-shadow: 0px 0px 10px #a0c15a;
    border-width:0px;
}

.termStatusRed {
    border-color: 	#e4604a;
    box-shadow: 0px 0px 10px #e4604a;
    border-width:0px;
}
